@import '../../scss/utils/color.scss';

.hero-container {
  background-image: url('../../assets/img/hero-bg-mobile.png');
  background-repeat: no-repeat;
  background-size: 100% calc(100% + 50px);
}

.hero-illustration-wrapper {
  padding: 0;
}

.hero-title {
  width: 100%;
  height: 141px;
}
.hero-desc {
  margin-top: 24px;
  color: $white;
  font-size: 15px;
  font-weight: 500;
}

.join-our-guild-btn {
  border-radius: 10px;
  border: none;
  background-color: black;
  padding: 16px 18px;
  color: $white;
  font-size: 15px;
  font-weight: 600;
}

.join-our-guild-btn-blue {
  border-radius: 10px;
  border: none;
  background-color: #a743f3;
  padding: 10px 14px;
  color: $white;
  font-size: 15px;
  font-weight: 700;
  width: 199px;
  margin: auto;
}
.hero-text-wrapper {
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .hero-container {
    background-image: url('../../assets/img/hero-background.png');
    background-repeat: no-repeat;
    background-size: 100% calc(95% + 8px) !important;
  }

  .hero-illustration-wrapper {
    padding-bottom: 2rem;
  }

  .hero-text-wrapper {
    width: 75%;
  }

  .join-our-guild-btn-blue {
    border-radius: 10px;
    border: none;
    background-color: #a743f3;
    padding: 16px 18px;
    color: $white;
    font-size: 15px;
    font-weight: 700;
  }
}
