@import '../../scss/utils/color.scss';
.container-footer {
  position: fixed;
  bottom: 0px;
  background-color: $black;
  padding: 9px 12px;
  // height: 100%;
  width: 100%;
  z-index: 30;

  .text-footer {
    font-weight: 700;
    font-size: 4px;
    color: #ffffff;
  }
}

@media only screen and (max-width: 768px) {
  .container-footer {
    .text-footer {
      font-size: 12px;
      line-height: 20px;
    }
  }
}
