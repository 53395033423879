$small-mobile-title: 31px;
$small-mobile-description: 11px;

$mobile-title: 31px;
$mobile-description: 13px;

$tablet-title: 25px;
$tablet-description: 10px;

$desktop-title: 30px;
$desktop-description: 12px;

$large-desktop-title: 45px;
$large-desktop-description: 18px;
