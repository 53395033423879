.pd-toast {
  &-header {
    p {
      font-size: 14px;
      font-weight: 700;
      color: #000;
    }
  }

  &-body {
    p {
      font-size: 14px;
      font-weight: 700;
      color: #000;
    }
  }
}
