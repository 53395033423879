$small-mobile-title-line-height: 36px;
$small-mobile-description-line-height: 13px;

$mobile-title-line-height: 36px;
$mobile-description-line-height: 16px;

$tablet-title-line-height: 36px;
$tablet-description-line-height: 13px;

$desktop-title-line-height: 36px;
$desktop-description-line-height: 15px;

$large-desktop-title-line-height: 45px;
$large-desktop-description-line-height: 22px;
