@import '../../scss/utils/color.scss';
@import '../../scss/utils/fontSize.scss';
@import '../../scss/utils/lineHeight.scss';

.mint-badge {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  padding: 120px 30px;
  .left {
    width: 100%;
    .title {
      max-width: 240px;
      letter-spacing: 5%;
      font-family: 'Akira';
      font-weight: 800;
      color: $black;
      font-size: $small-mobile-title;
      line-height: $small-mobile-title-line-height;
      span {
        font-size: $mobile-title;
        line-height: $mobile-title-line-height;
        font-family: 'Akira';
        font-weight: 800;
        color: $yellow;
      }
    }

    .description {
      margin-top: 25px;
      &-normal {
        max-width: 280px;
        font-size: $small-mobile-description;
        line-height: $small-mobile-description-line-height;
        font-weight: 500;
        color: #000;
      }

      &-bold {
        max-width: 280px;
        font-size: $small-mobile-description;
        line-height: $small-mobile-description-line-height;
        font-weight: 700;
        color: #000;
      }
    }

    .price {
      p {
        color: $yellow;
        font-size: 18px;
        line-height: 36px;
        font-family: 'Akira';
        font-weight: 800;
        letter-spacing: 0.05em;
      }
    }

    .supply {
      p {
        color: #a8a6a6;
        font-size: 12px;
        font-weight: 700;
        line-height: 15px;
      }
    }

    .connect-button {
      border: none;
      background-color: black;
      color: #fff;
      font-weight: 700;
      font-size: $small-mobile-description;
      padding: 8px 16px;
      font-family: 'Montserrat';
      &:hover {
        background-color: #b4b5b5;
      }
    }

    .connect-button-disabled {
      cursor: not-allowed;
      border: none;
      background-color: #b4b5b5;
      color: #fff;
      font-weight: 700;
      font-size: $small-mobile-description;
      padding: 8px 16px;
      font-family: 'Montserrat';
    }

    .nav {
      display: flex;
      flex-direction: column;
      div {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid $grey;
        padding: 0 8px;

        .name {
          font-weight: 700;
          font-size: $small-mobile-description;
        }
        .plus {
          font-weight: 900;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: black;
          font-size: 14px;
        }
        .name,
        .plus {
          margin: 0;
          padding: 10px 0;
        }
      }
      div:last-child {
        border-bottom: 1px solid $grey;
      }
    }
  }
  .right {
    margin-top: 40px;

    height: 100%;
    .hero-wrapper {
      text-align: center;
      img {
        max-height: 244px;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      em {
        margin-top: 20px;
        font-weight: 500;
        font-size: 8px;
        color: #000;
      }
    }
  }
}

@media only screen and (min-width: 425px) {
  .mint-badge {
    .left {
      .title {
        font-size: $mobile-title;
        line-height: $mobile-title-line-height;
        span {
          font-size: $mobile-title;
          line-height: $mobile-title-line-height;
        }
      }

      .description {
        margin-top: 30px;
        &-normal {
          font-size: $mobile-description;
          line-height: $mobile-description-line-height;
        }

        &-bold {
          font-size: $mobile-description;
          line-height: $mobile-description-line-height;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .mint-badge {
    flex-direction: row;
    align-items: flex-start;
    // justify-content: space-between;
    padding: 120px 40px;
    .left {
      max-width: 250px;
      .title {
        font-size: $tablet-title;
        line-height: $tablet-title-line-height;
        span {
          font-size: $tablet-title;
          line-height: $tablet-title-line-height;
        }
      }

      .description {
        margin-top: 16px;
        max-width: 200px;
        &-normal {
          font-size: $tablet-description;
          line-height: $tablet-description-line-height;
        }

        &-bold {
          font-size: $tablet-description;
          line-height: $tablet-description-line-height;
        }
      }
      .price {
        p {
          font-size: 12px;
          line-height: 36px;
        }
      }

      .supply {
        p {
          font-size: 10px;
          line-height: 22px;
        }
      }
    }
    .right {
      // flex: 1;
      margin-top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .hero-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 350px;
          height: 300px;
          max-width: 100%;
          max-height: 100%;
        }
        em {
          margin-top: 10px;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .mint-badge {
    padding: 120px 50px;

    .left {
      margin-top: 10px;
      max-width: 250px;
      .title {
        font-size: $desktop-title;
        line-height: $desktop-title-line-height;
        span {
          font-size: $desktop-title;
          line-height: $desktop-title-line-height;
        }
      }

      .description {
        max-width: 250px;
        &-normal {
          font-size: $desktop-description;
          line-height: $desktop-description-line-height;
        }

        &-bold {
          font-size: $desktop-description;
          line-height: $desktop-description-line-height;
        }
      }
      .price {
        p {
          font-size: 14px;
          line-height: 36px;
        }
      }

      .supply {
        p {
          font-size: 10px;
          line-height: 22px;
        }
      }
    }
    .right {
      .hero-wrapper {
        img {
          width: 400px;
          height: 400px;
          max-width: 100%;
          max-height: 100%;
        }
        em {
          font-size: 12px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .mint-badge {
    padding: 120px 70px;

    .left {
      min-width: 500px;
      .title {
        min-width: 400px;
        font-size: $large-desktop-title;
        line-height: $large-desktop-title-line-height;
        span {
          font-size: $large-desktop-title;
          line-height: $large-desktop-title-line-height;
        }
      }

      .description {
        min-width: 450px;
        &-normal {
          max-width: 400px;
          font-size: $large-desktop-description;
          line-height: $large-desktop-description-line-height;
        }

        &-bold {
          max-width: 400px;
          font-size: $large-desktop-description;
          line-height: $large-desktop-description-line-height;
        }
      }
      .price {
        p {
          font-size: 20px;
          line-height: 36px;
        }
      }

      .supply {
        p {
          font-size: 18px;
          line-height: 22px;
        }
      }

      .nav {
        width: 262px;
      }
    }
    .right {
      flex: 1;
      .hero-wrapper {
        img {
          width: 600px;
          height: 450px;
          max-width: 100%;
          max-height: 100%;
        }
        em {
          font-size: 14px;
        }
      }
    }
  }
}
