@import '../../scss/utils/color.scss';
.pd-nav {
  z-index: 10;
  position: fixed;
  top: 0;
  width: 100%;
  ul {
    list-style-type: none;
  }
  &-logo {
    width: 93px;
    height: 34px;
    object-fit: cover;
  }
  &-button {
    background-color: transparent;
    border: none;
  }
  &-hamburger-icon {
    width: 46px;
    height: 31px;
    cursor: pointer;
  }
  &-list-wrapper {
    background-color: white;
    opacity: 0.95;
    width: 186px;
    &-item {
      border-bottom: 1px solid #cacaca;
      padding: 16px 20px;
      text-align: end;
      a,
      p {
        text-align: end;
        text-decoration: none;
        color: black;
        font-weight: 700;
      }
    }
  }
  &-list-desktop-wrapper {
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0;
    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(217, 217, 217, 0.8);
      -webkit-transform: skew(-20deg);
      -moz-transform: skew(-20deg);
      transform: skew(-20deg);
      padding: 4px 12px;
      margin-right: 10px;
      width: 56px;
      height: 12px;
      cursor: pointer;
      a,
      p {
        white-space: nowrap;
        font-size: 5px;
        font-weight: 700;
        text-decoration: none;
        color: $white !important;
        -webkit-transform: skew(20deg);
        -moz-transform: skew(20deg);
        transform: skew(20deg);
      }
      &:hover {
        background-color: #6327af;
      }
    }
  }
}

.nav-content {
  background-color: $black;
}

.navbar-nav {
  background-color: #ffffff;
}

@media only screen and (min-width: 992px) {
  .pd-nav {
    &-list-desktop-wrapper {
      &-item {
        width: 71px;
        height: 16px;
      }
      a,
      p {
        font-size: 7px;
      }
    }
  }
}
@media only screen and (min-width: 1200px) {
  .pd-nav {
    &-list-desktop-wrapper {
      &-item {
        width: 108px;
        height: 25px;
      }
      a,
      p {
        font-size: 10px;
      }
    }
  }
}
