@import '../../../scss/utils/color.scss';

.bold {
  font-weight: 800;
}

.staking-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;

  &.confirm {
    padding-block: 24px;
  }

  &-confirm {
    &-title {
      font-weight: 800;
      font-size: 20px;
      margin-top: 0.5em;
      margin-bottom: 0.25em;
      text-align: center;

      &.orange {
        color: $yellow !important;
      }
    }

    &-subtitle {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
    }
  }

  &-image {
    width: 75%;
  }

  &-title {
    font-size: 16px;
    font-weight: 800;
    text-align: center;
  }

  &-subtitle {
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 0;
  }

  &-input {
    &-label {
      font-size: 10px;
      display: block;
      text-align: center;
      margin-top: 1rem;
    }
    &-field {
      background-color: #f5f5f5;
      border-radius: 8px;
      border: thin solid #b8b8b8;
      font-size: 10px;
      margin-bottom: 10px;
      margin-top: 14px;
      padding: 10px;
      text-align: center;
      width: 100%;
    }
  }

  &-button {
    border: none;
    color: white;
    background-color: black;
    font-size: 10px;
    max-width: 120px;
    padding-block: 0.5rem;
    width: 100%;
    cursor: pointer;
    font-weight: 800;

    &.yellow {
      color: white !important;
      background-color: $yellow;
    }

    &.gray {
      color: white !important;
      background-color: $grey;
    }
  }
}
