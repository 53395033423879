.home-sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  left: 25px;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;

  &-button {
    width: 10px;
    height: 46px;
    object-fit: cover;
    cursor: pointer;
  }
}

.home-sidebar-mobile {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  left: 50%;
  top: 90%;
  transform: translate(-50%, -50%);
  z-index: 10;
  // background-color: black;

  &-button {
    width: 36px;
    height: 8px;
    object-fit: cover;
    cursor: pointer;
  }
}

// @media only screen and (max-width: 428px) {
//   .home-sidebar {
//     flex-direction: row;
//   }
// }
