@import '../../scss/utils/color.scss';
@import '../../scss/utils/fontSize.scss';
@import '../../scss/utils/lineHeight.scss';

.success-page {
  min-height: 100vh;
  padding: 120px 30px;
  text-align: center;
  .head {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title {
      .black,
      .yellow {
        font-family: 'Akira';
        font-size: 23px;
        font-weight: 800;
        line-height: 25px;
        letter-spacing: 5%;
      }
      .black {
        color: $black;
      }

      .yellow {
        color: $yellow;
      }
    }
  }
  .body {
    img {
      margin-top: 30px;
      max-height: 244px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .foot {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title {
      margin: 30px 0 0 0;
      font-size: 13px;
      font-weight: 800;
      color: $black;
      line-height: 15px;
    }
    .verify {
      margin: 8px 0 0 0;
      font-size: 9px;
      font-weight: 500;
      color: #000;
      line-height: 11px;
    }
    .verify-button {
      margin-top: 20px;
      border: none;
      background-color: $black;
      width: 140px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      a {
        cursor: pointer;
        text-decoration: none;
        font-size: 12px;
        font-weight: 700;
        color: $white;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .success-page {
    .head {
      .title {
        .black,
        .yellow {
          font-size: 25px;
          line-height: 28px;
        }
      }

      .description {
        width: 250px;
        p {
          margin-top: 10px;
          font-size: 10px;
          font-weight: 500;
          line-height: 13px;
          color: $black;
        }
      }
    }
    .body {
      img {
        max-height: 298px;
      }
    }
    .foot {
      .title {
        font-size: 10px;
        line-height: 13px;
      }
      .verify {
        font-size: 7px;
        line-height: 9px;
      }

      .verify-button {
        width: 119px;
        height: 24px;
        a {
          font-size: 7px;
          font-weight: 700;
          color: $white;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .success-page {
    .head {
      .title {
        .black,
        .yellow {
          font-size: 25px;
          line-height: 28px;
        }
      }

      .description {
        width: 100%;
        p {
          margin-top: 15px;
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
    .body {
      img {
        max-height: 385px;
      }
    }
    .foot {
      .title {
        margin-top: 38px;
        font-size: 15px;
        line-height: 18px;
      }
      .verify {
        margin-top: 10px;
        font-size: 10px;
        line-height: 12px;
      }

      .verify-button {
        margin-top: 30px;
        width: 153px;
        height: 31px;
        padding: 12px 30px;
        a {
          font-size: 10px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .success-page {
    .head {
      .title {
        .black,
        .yellow {
          font-size: 45px;
          line-height: 48px;
        }
      }

      .description {
        width: 100%;
        p {
          margin-top: 15px;
          font-size: 18px;
          font-weight: 500;
          line-height: 22px;
          color: $black;
        }
      }
    }
    .body {
      img {
        max-height: 500px;
      }
    }
    .foot {
      .title {
        margin-top: 38px;
        font-size: 20px;
        line-height: 24px;
      }
      .verify {
        margin-top: 10px;
        font-size: 18px;
        line-height: 22px;
      }

      .verify-button {
        margin-top: 30px;
        width: 233px;
        height: 45px;
        padding: 12px 30px;
        a {
          font-size: 16px;
        }
      }
    }
  }
}
