.modal {
  background-color: rgba(0, 0, 0, 0.7);
  &-content {
    border-radius: 0;
  }
  &-dialog {
    width: 359px;
    margin-left: auto;
    margin-right: auto;
  }
  &-header {
    border: none;
    position: relative;
    .btn-close {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }
  &-title {
    margin: 30px auto 10px auto;
    font-size: 26px;
    font-weight: 800;
    color: #191919;
    font-family: 'Akira' !important;
    // letter-spacing: 5%;
    overflow: hidden;
    // height: 100px;
    &-yellow {
      font-size: 26px;
      line-height: 36px;
      font-weight: 800;
      font-family: 'Akira' !important;
      // letter-spacing: 5%;
      color: #ffc700;
    }
  }
}

.modal-friends {
  &-list {
    // MOZILLA
    // scrollbar-color: black grey;
    // scrollbar-width: thin;
    padding-top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
    flex-wrap: wrap;
    direction: rtl;
    overflow-y: scroll;
    overflow-x: hidden;
    gap: 15px;
  }
  &-logo {
    direction: ltr;
  }
}

@media only screen and (min-width: 768px) {
  .modal {
    &-dialog {
      width: 657px;
      height: 351px;
    }
  }
}
@media only screen and (min-width: 992px) {
  .modal {
    &-dialog {
      width: 849px;
      height: 454px;
    }
  }
}
@media only screen and (min-width: 1200px) {
  .modal {
    &-dialog {
      width: 1294px;
      height: 692px;
    }
  }
}
