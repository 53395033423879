.about-wrapper {
  background-image: url('../../assets/img/about/about-background-mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  padding-top: 10vh;
}

.coin {
  width: 386px;
  height: 396px;
  object-fit: contain;
}

.about-text {
  font-family: 'Akira' !important;
  color: #ffffff;
}

.mint-badge-btn {
  border-radius: 10px;
  border: none;
  background-color: black;
  padding: 12px 40px;
  color: white;
  font-size: 15px;
  font-weight: 700;
}

.mint-link {
  height: 100%;
}
.btn-mint-link-ranker-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: auto;
  height: 60px;
  background-color: #ffffff;
}
.mint-link-ranker-page-image {
  height: 100%;
  object-fit: contain;
}

.title-dropdown {
  font-size: 13px;
  padding: 9px 7px;
}
.title-dropdown svg {
  width: 11px;
  height: 11px;
}
.title-dropdown svg:nth-child(1) {
  opacity: 0;
}

@media only screen and (min-width: 1024px) {
  .about-wrapper {
    background-image: url('../../assets/img/about/about-background.png');
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
  }

  .title-dropdown {
    font-size: 17px;
  }

  .title-dropdown svg {
    width: 22px;
    height: 22px;
  }

  .title-dropdown svg:nth-child(1) {
    opacity: 1;
  }
}
