.ranker-modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(#000000, 0.5);
  backdrop-filter: blur(2px);
  z-index: 9999;
}

.ranker-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 1px 2px 10px -1px #00000045;
  background-color: white;
  border-radius: 10px;
  padding: 1.5rem;
  width: 100%;
  z-index: 9999;
}

.ranker-modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  width: 1.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.75rem;
  border: none;
  background-color: transparent;
  transition: background-color 0.3s;
  border-radius: 5px;
  &:hover {
    background-color: rgba(15, 15, 15, 0.1);
  }
  &:active {
    background-color: rgba(15, 15, 15, 0.2);
  }
}
